<!--
#***********************************************
#
#      Filename: src/views/Report/DiscountReport.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 优免报表
#        Create: 2021-10-28 16:47:02
# Last Modified: 2021-11-15 08:58:23
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @export="handleExport"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table
      :data="dataList"
      stripe
      border
      show-summary
      size="mini"
      :summary-method="getSummaries"
      @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { reportAll } from '@/api/consumeLimit'
  import { parkList } from '@/api/community'
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'DiscountReport',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: ''
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: ''
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'select',
                name: 'parkings',
                placeholder: '请选择停车场',
                options: []
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '日期',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'date'
          },
          {
            label: '区域名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'communityId'
          },
          {
            label: '停车场名称',
            isShow: true,
            align: 'center',
            prop: 'parkingInfo.name'
          },
          {
            label: '优免金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalLmtPrice'
          },
          {
            label: '实际减免金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'actualTotalLmtPrice'
          },
          {
            label: '商户应缴（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalMchMightPrice'
          },
          {
            label: '年免金（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'applyYearExemption'
          }
        ],

        /**
         * 所有停车场id数组
         */
        pids: []
      }
    },

    mounted() {
      this.getList()
      this.getParkList()
    },

    methods: {
      /**
       * 总计方法
       */
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = '总计'
            return
          }
          if (index === 4 || index === 5 || index === 6 || index === 7) {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ' 元'
            }
          }
        })
        return sums
      },
      /**
       * 获取停车场列表
       */
      getParkList() {
        const queryData = {}
        queryData.current = 1
        queryData.size = 9999
        parkList(queryData).then((res) => {
          if (res.result) {
            const options = [{ label: '全部停车场', value: 0 }]
            res.data.records.forEach((item) => {
              options.push({ label: item.name, value: item.id })
              this.pids.push(item.id)
            })
            const arr = [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'select',
                name: 'parkings',
                placeholder: '请选择停车场',
                options: options
              }
            ]
            this.$set(this.settings.dropDownFilterArray, 'arr', arr)
          } else {
            this.noticeError(res.message)
          }
        })
      },

      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        if (this.filterData.parkings) {
          queryData.parkingIds = this.filterData.parkings
        } else {
          queryData.parkingIds = this.pids.join(',')
        }
        this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
        if (this.filterData.dateRange) {
          queryData.startDate = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
          queryData.endDate = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
        }

        reportAll(queryData)
          .then((res) => {
            this.dataList = res.data.records
            this.total = res.data.total
          })
          .catch((err) => {
            console.error('商户优免报表查询出错', err)
          })
          .finally(() => {
            this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出优免报表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.current = 1
            queryData.size = 9999
            if (this.filterData.parkings) {
              queryData.parkingIds = this.filterData.parkings
            } else {
              queryData.parkingIds = this.pids.join(',')
            }
            this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
            if (this.filterData.dateRange) {
              queryData.startDate = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
              queryData.endDate = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
            }

            reportAll(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'parkingInfo.name') {
                        return v.parkingInfo.name
                      } else if (j === 'toTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '优免报表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
