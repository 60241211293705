/*
#***********************************************
#
#      Filename: src/api/blackList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 商停优免
#        Create: 2021-11-23 00:02:38
# Last Modified: 2021-11-26 13:56:29
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 优免流水列表
export function logList(data) {
  return request({
    url: '/consumeLimit/log/list',
    method: 'get',
    params: data
  })
}

// 删除优免
export function deleteItem(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/consumeLimit/rule/delete/' + id,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 优免信息
export function info(id, data) {
  return request({
    url: '/consumeLimit/rule/info/' + id,
    method: 'get',
    params: data
  })
}

// 优免名单列表
export function list(data) {
  return request({
    url: '/consumeLimit/rule/list',
    method: 'get',
    params: data
  })
}

// 保存配置
export function add(data) {
  const formData = qs.stringify(data)
  return request({
    url: '/consumeLimit/rule/put',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 商户列表
export function merchantList(data) {
  return request({
    url: '/merchant/list',
    method: 'get',
    params: data
  })
}

// 单个停车场各商户日报表
export function reportList(id, data) {
  return request({
    url: '/consumeLimit/report/' + id + '/daily',
    method: 'get',
    params: data
  })
}

// 所有停车场所有商户总报表
export function reportAll(data) {
  return request({
    url: '/consumeLimit/report/daily/all',
    method: 'get',
    params: data
  })
}

// 商铺租金管理列表
export function propertyList(data) {
  return request({
    url: '/housing/shop/sign/list/' + data,
    method: 'get'
  })
}
// 删除单个租金管理
export function deleteRent(id, data) {
  return request({
    url: '/api/manage/housing/shop/sign/delete/' + id,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
